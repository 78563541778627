import React from 'react';
import PropTypes from 'prop-types';
import { RedIndicator } from './red-indicator.css';

const _RedIndicator = ({ children }) => (
  <RedIndicator>
    <div className="outer-dot">
      <div className="dot"></div>
    </div>
    {children}
  </RedIndicator>
);

_RedIndicator.propTypes = {
  children: PropTypes.node.isRequired,
};

export default _RedIndicator;
